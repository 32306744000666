// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-single-post-js": () => import("C:\\Users\\vbarbuto\\Desktop\\M2\\hr_gatsby\\src\\templates\\single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-pages-404-js": () => import("C:\\Users\\vbarbuto\\Desktop\\M2\\hr_gatsby\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-en-js": () => import("C:\\Users\\vbarbuto\\Desktop\\M2\\hr_gatsby\\src\\pages\\about.en.js" /* webpackChunkName: "component---src-pages-about-en-js" */),
  "component---src-pages-about-it-js": () => import("C:\\Users\\vbarbuto\\Desktop\\M2\\hr_gatsby\\src\\pages\\about.it.js" /* webpackChunkName: "component---src-pages-about-it-js" */),
  "component---src-pages-index-en-js": () => import("C:\\Users\\vbarbuto\\Desktop\\M2\\hr_gatsby\\src\\pages\\index.en.js" /* webpackChunkName: "component---src-pages-index-en-js" */),
  "component---src-pages-index-js": () => import("C:\\Users\\vbarbuto\\Desktop\\M2\\hr_gatsby\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("C:\\Users\\vbarbuto\\Desktop\\M2\\hr_gatsby\\src\\pages\\privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

